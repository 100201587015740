import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  reslogo:
  {
      height: '70px',
      margin: '10px',   
  },
  logo:
  {
      height: '90px',
      margin: '10px',
      marginTop: '20px',
      marginLeft: '30px',
  },
  toplink:
  { 
    fontFamily: 'Lato',
    fontSize: '20px',
    textDecoration: 'none',
    color: 'black',  
    margin: '5px',
    padding:'10px',
  '&:hover': {
    background: "lightgrey",
  },
  },
  });