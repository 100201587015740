export default [
 {
   Manufacturer: "Anchorlift",
   Model: "All Dolphin models",
   GypsyOrChain: "option 2",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "All Dolphin models",
   GypsyOrChain: "option 3",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Aquarius A2524",
   GypsyOrChain: "option 1",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Aquarius A2524",
   GypsyOrChain: "option 3",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Barracuda 900 - B912",
   GypsyOrChain: "option 2",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Barracuda ALU 600 - BA612",
   GypsyOrChain: "option 2",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Barracuda ALU 900 - BA912",
   GypsyOrChain: "option 2",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Mako M1212",
   GypsyOrChain: "option 2",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Mako M1212",
   GypsyOrChain: "option 3",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Mako M1512",
   GypsyOrChain: "option 1",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Mako M1512",
   GypsyOrChain: "option 3",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Mako M1524",
   GypsyOrChain: "option 2",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Mako M1524",
   GypsyOrChain: "option 3",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Anchorlift",
   Model: "Stingray S1012",
   GypsyOrChain: "option 2",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Good",
   Model: "F850A",
   GypsyOrChain: "-",
   ChainSize: "5/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Good",
   Model: "F850C",
   GypsyOrChain: "-",
   ChainSize: "5/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX1",
   GypsyOrChain: "000",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX1",
   GypsyOrChain: "001",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX1",
   GypsyOrChain: "006",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX2",
   GypsyOrChain: "000",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX2",
   GypsyOrChain: "001",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX2",
   GypsyOrChain: "006",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX3",
   GypsyOrChain: "000",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "CPX3",
   GypsyOrChain: "001",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "H2",
   GypsyOrChain: "505",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "H2",
   GypsyOrChain: "506",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "H3",
   GypsyOrChain: "505",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "H3",
   GypsyOrChain: "506",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Fish 1000",
   GypsyOrChain: "504",
   ChainSize: "5/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Fish 1000 (USA)",
   GypsyOrChain: "516",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Fish 700",
   GypsyOrChain: "502",
   ChainSize: "6mm",
   ISOGrade: "",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Fish 700",
   GypsyOrChain: "503",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Series 1000",
   GypsyOrChain: "502",
   ChainSize: "6mm",
   ISOGrade: "",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Series 1000 (USA)",
   GypsyOrChain: "516",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Series 700",
   GypsyOrChain: "502",
   ChainSize: "6mm",
   ISOGrade: "",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Series 700",
   GypsyOrChain: "503",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "Pro-Sport 550",
   GypsyOrChain: "-",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V1",
   GypsyOrChain: "000",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V1",
   GypsyOrChain: "001",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V1",
   GypsyOrChain: "006",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V1 MO",
   GypsyOrChain: "000",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V1 MO",
   GypsyOrChain: "001",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V2",
   GypsyOrChain: "000",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V2",
   GypsyOrChain: "001",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V2",
   GypsyOrChain: "006",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V3",
   GypsyOrChain: "000",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V3",
   GypsyOrChain: "001",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V4",
   GypsyOrChain: "201",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V5",
   GypsyOrChain: "201",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V6",
   GypsyOrChain: "1/2\"",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V6",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V6",
   GypsyOrChain: "14mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V700",
   GypsyOrChain: "-",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lewmar",
   Model: "V8",
   GypsyOrChain: "1/2\"",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lewmar",
   Model: "V8",
   GypsyOrChain: "12-13mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Atlas",
   GypsyOrChain: "6mm",
   ChainSize: "3/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Atlas",
   GypsyOrChain: "7mm",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Atlas",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Cayman 88",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Cayman 88",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Cayman 88",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Dorado",
   GypsyOrChain: "6mm",
   ChainSize: "3/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Dorado",
   GypsyOrChain: "7mm",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Dorado",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Ercole Horizontal",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Ercole Vertical",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Falkon",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Falkon",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Falkon",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Kobra",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Kobra",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Kobra",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 1000",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 1000",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 1000",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 1500",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 1500",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 1500",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 1500",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 2000",
   GypsyOrChain: "1/2\" BBB",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 2000",
   GypsyOrChain: "1/2\" HT",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 2000",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 2000",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Project 2000",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Royal",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Royal",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Royal",
   GypsyOrChain: "6mm",
   ChainSize: "3/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Royal",
   GypsyOrChain: "7mm",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Royal",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Super Ercole Horizontal",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Tigress",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Tigress",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Tigress",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Titan",
   GypsyOrChain: "1/2\" BBB",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Titan",
   GypsyOrChain: "1/2\" HT",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Titan",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "Titan",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "Titan",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X0",
   GypsyOrChain: "7mm",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X1",
   GypsyOrChain: "7mm",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X1",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X2 & X2 ALU",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X2 & X2 ALU",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X2 & X2 ALU",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3 & X3 ALU",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3 & X3 ALU",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3 & X3 ALU",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3 & X3 ALU",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3.5",
   GypsyOrChain: "1/2\" BBB",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3.5",
   GypsyOrChain: "1/2\" HT",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3.5",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3.5",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X3.5",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X4",
   GypsyOrChain: "1/2\" BBB",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X4",
   GypsyOrChain: "1/2\" HT",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X4",
   GypsyOrChain: "10 DIN 766",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X4",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X4",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X5",
   GypsyOrChain: "1/2\" BBB",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Lofrans",
   Model: "X5",
   GypsyOrChain: "1/2\" HT",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Lofrans",
   Model: "X5",
   GypsyOrChain: "12mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "500",
   GypsyOrChain: "3132/201C",
   ChainSize: "3/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "500",
   GypsyOrChain: "3132/203C",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "1000 - 1500",
   GypsyOrChain: "3173/100C",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "1000 - 1500",
   GypsyOrChain: "3173/105C",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "1000 - 1500",
   GypsyOrChain: "3173/110C",
   ChainSize: "1/4\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "1000 - 1500",
   GypsyOrChain: "3173/111C",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "1000 - 1500",
   GypsyOrChain: "3173/114C",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "2200 - 2500",
   GypsyOrChain: "3231/012C",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "2200 - 2500",
   GypsyOrChain: "3231/033C",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "2200 - 2500",
   GypsyOrChain: "3231/046C",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "2200 - 2500",
   GypsyOrChain: "3231/054C",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "2200 - 2500",
   GypsyOrChain: "3231/058C",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 non-band brake",
   GypsyOrChain: "3182/016C",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 non-band brake",
   GypsyOrChain: "3182/033C",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 non-band brake",
   GypsyOrChain: "3182/042C",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 non-band brake",
   GypsyOrChain: "3182/043C",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 non-band brake",
   GypsyOrChain: "3182/054C",
   ChainSize: "3/8\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 non-band brake",
   GypsyOrChain: "3182/058C",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 with band brake",
   GypsyOrChain: "5437/016C",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 with band brake",
   GypsyOrChain: "5437/042C",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 with band brake",
   GypsyOrChain: "5437/043C",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 with band brake",
   GypsyOrChain: "5437/054C",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "3500 - 4000 with band brake",
   GypsyOrChain: "5437/058C",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "4500 with band brake",
   GypsyOrChain: "3710/003C",
   ChainSize: "5/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "4500 with band brake",
   GypsyOrChain: "3710/012C",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "4500 with band brake",
   GypsyOrChain: "3710/015C",
   ChainSize: "1/2\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "4500 with band brake",
   GypsyOrChain: "3710/054C",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "6000 with band brake",
   GypsyOrChain: "2514/002C",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "6000 with band brake",
   GypsyOrChain: "2514/003C",
   ChainSize: "5/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "6000 with band brake",
   GypsyOrChain: "2514/005C",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "8000 - 11000 with band brake",
   GypsyOrChain: "2512/008C",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "8000 - 11000 with band brake",
   GypsyOrChain: "2512/014C",
   ChainSize: "3/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "Freedom",
   GypsyOrChain: "P100030",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "Freedom",
   GypsyOrChain: "P100033",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "HRC 10",
   GypsyOrChain: "P103315",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "HRC 10",
   GypsyOrChain: "P103316",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "HRC FF 6,7,8",
   GypsyOrChain: "P102840",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "HRC FF 6,7,8",
   GypsyOrChain: "P102846",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "HRC FF 6,7,8",
   GypsyOrChain: "P102859",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "HRC FF 6,7,8",
   GypsyOrChain: "P102860",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "Liberty",
   GypsyOrChain: "P101525",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "Liberty",
   GypsyOrChain: "P101542",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 10",
   GypsyOrChain: "P103308",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 10",
   GypsyOrChain: "P103309",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 12",
   GypsyOrChain: "P103317",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 12",
   GypsyOrChain: "P103318",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 12",
   GypsyOrChain: "P103321",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 12",
   GypsyOrChain: "P103322",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 6",
   GypsyOrChain: "P103314",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 8",
   GypsyOrChain: "P103310",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Maxwell",
   Model: "RC 8",
   GypsyOrChain: "P103311",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Powerwinch",
   Model: "Class 31'",
   GypsyOrChain: "-",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Powerwinch",
   Model: "Class 36'",
   GypsyOrChain: "-",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Powerwinch",
   Model: "Class 41'",
   GypsyOrChain: "-",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Powerwinch",
   Model: "Class 46'",
   GypsyOrChain: "-",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Powerwinch",
   Model: "Sport Fish 450",
   GypsyOrChain: "-",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Aleph 700W, 1000W, 1500W",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Aleph 700W, 1000W, 1500W",
   GypsyOrChain: "8mm - 5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Antares 1000W, 1500W",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Antares 1000W, 1500W",
   GypsyOrChain: "8mm - 5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Argo 1700, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Argo 1700, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "12 - 13mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Argo 1700, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "14mm",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Aries 300W, 500W",
   GypsyOrChain: "1/4\"",
   ChainSize: "1/4\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Aries 700W, 1000W, 1400W",
   GypsyOrChain: "10mm",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Aries 700W, 1000W, 1400W",
   GypsyOrChain: "8mm - 5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Aster 300W, 500W, 700W, 1000W",
   GypsyOrChain: "5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Aster 300W, 500W, 700W, 1000W",
   GypsyOrChain: "7mm - 1/4\"",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Aster 300W, 500W, 700W, 1000W",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Crystal 600, 1000, 1100 (500W, 800W)",
   GypsyOrChain: "5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Crystal 600, 1000, 1100 (500W, 800W)",
   GypsyOrChain: "7mm - 1/4\"",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Crystal 600, 1000, 1100 (500W, 800W)",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Duke 3000, 3500, 4000, 5500, Hyd. Motor",
   GypsyOrChain: "12 - 13mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Duke 3000, 3500, 4000, 5500, Hyd. Motor",
   GypsyOrChain: "14mm",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Dylan 1500, 2000, 3000, Hyd. Motor",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Dylan 1500, 2000, 3000, Hyd. Motor",
   GypsyOrChain: "12 - 13mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Eagle 700W, 1000W, 1400W",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Eagle 700W, 1000W, 1400W",
   GypsyOrChain: "8mm - 5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Flair 300W, 500W",
   GypsyOrChain: "1/4\"",
   ChainSize: "1/4\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Flair 700W, 1000W, 1400W",
   GypsyOrChain: "8mm - 5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Genius U, UFF,  600, 1000, 1100",
   GypsyOrChain: "5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Genius U, UFF,  600, 1000, 1100",
   GypsyOrChain: "7mm - 1/4\"",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Genius U, UFF,  600, 1000, 1100",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Hector 700W, 1000W, 1500W",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Hector 700W, 1000W, 1500W",
   GypsyOrChain: "8mm - 5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "MiniGenius 150, 250, 250FF",
   GypsyOrChain: "5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "MiniGenius 150, 250, 250FF",
   GypsyOrChain: "7mm - 1/4\"",
   ChainSize: "1/4\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "MiniGenius 150, 250, 250FF",
   GypsyOrChain: "8mm",
   ChainSize: "5/16\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Regal 1700, 2000, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Regal 1700, 2000, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "12 - 13mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Regal 1700, 2000, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "14mm",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Rider 1700, 2000, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Rider 1700, 2000, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "12 - 13mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Rider 1700, 2000, 2300, 3000, Hyd. Motor",
   GypsyOrChain: "14mm",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Rider 500W",
   GypsyOrChain: "1/4\"",
   ChainSize: "1/4\"",
   ISOGrade: "",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Rider 700W, 1000W, 1400W",
   GypsyOrChain: "10mm - 3/8\"",
   ChainSize: "3/8\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Rider 700W, 1000W, 1400W",
   GypsyOrChain: "8mm - 5/16\"",
   ChainSize: "5/16\"",
   ISOGrade: "*",
   BBB: "*"
 },
 {
   Manufacturer: "Quick",
   Model: "Roy 3000, 3500, 4000, 5500, Hyd. Motor",
   GypsyOrChain: "12 - 13mm",
   ChainSize: "7/16\"",
   ISOGrade: "*",
   BBB: ""
 },
 {
   Manufacturer: "Quick",
   Model: "Roy 3000, 3500, 4000, 5500, Hyd. Motor",
   GypsyOrChain: "14mm",
   ChainSize: "1/2\"",
   ISOGrade: "*",
   BBB: ""
 }
]