import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({  
  root: {
    flexGrow: 1,
  },
  map: {
    height: 'auto',
    maxWidth: '70px',
  },
  content: {
      padding: '20px',
      textAlign: 'center', 
  },
  paper: {    
    padding: '5px',
    marginBottom: '10px'
  },
  text:{
    color: '#231f20',
    marginBottom: '40px',
    marginTop: '40px',
    fontFamily:'Lato',
    fontSize: '50px'
  },
  link:{
      textDecoration: 'none',
      color: 'black'
  },

  mpagecontent: {
    fontFamily: 'Lato',
    fontSize: '20px'
  },
  pagecontent: {
    fontFamily: 'Lato',
    fontSize: '22px'
  }
});