import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core';
import styles from './styles/ProductStyles'
import ModalImage from "react-modal-image";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FitmentChart from './FitmentChart/FitmentChart';
import DescriptionComponent from './DescriptionComponent';
import { Helmet } from "react-helmet";



export default function Din () {
    const classes = styles();
    return (
        <div>

<Helmet>
<title> Din 766 Galvanized</title>
</Helmet>
            <Container maxWidth="md" component="main" style={{minHeight:'50vh'}}>
        <Typography  align="center" className={classes.pagetitle} >
        Din 766 Galvanized
        </Typography>  

        
         <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={5} sm={5}>
            <div title="Trident Marine Din Chain Link">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/DIN-chain-single-link.jpg?v=1658180569"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/DIN-chain-single-link.jpg?v=1658180569" hideDownload={true} hideZoom={true}
                      className={classes.card} alt="Trident Marine Din Chain Link"
                     />     
                     </div>
              </Grid>  
              <Grid item  xs={10} md={5} sm={5}>
                <div  title="Trident Marine Din Chain Link Diagram">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585" hideDownload={true} hideZoom={true}
                      className={classes.card}
                      alt="Trident Marine Din Chain Link Diagram"
                    />   
                    </div>
              </Grid> 
        </Grid>

        <DescriptionComponent 
          product="Din 766 Galvanized"
          subtitle="DIN 766 chain has shorter links that allow the chain to articulate very well and smoothly operate in and out of chain lockers and across the chain gypsies."
          p1="Because the inside length and width of each chain link must consistently and properly fit the chain gypsies on anchor windlasses, each chain link is calibrated during the manufacturing process. Every 3 feet, a link of chain is embossed with “TD4” which indicates that it is a Trident Marine Chain DIN 766 chain product. To ensure long service life, a thick layer of zinc is applied through the hot dip galvanizing process."
          p2="Made from high tensile strength carbon steel, Trident Marine Chains DIN 766 Grade 40 anchor windlass chain is a short pitch type chain that makes it more flexible and suitable for working with anchor windlass gypsies. Each link of chain is proof tested and calibrated to assure strength and fit with the windlass gypsy. "
          p3="To provide long service life and guard against corrosion, Trident DIN 766 Windlass Anchor chains are hot dip galvanized. Trident Marine Chains DIN 766 Grade 40 windlass anchor chain is embossed every 1 meter with the “TD4” marking that indicates it to be a Trident DIN 766 Grade 40 chain."
        />

    <Typography  align="center" className={classes.pagesubtitle} gutterBottom>
    6 millimeter - 10 millimeter Diameter
     </Typography> 

    <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#272e74'}}>
          <TableRow>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Part Number</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Nominal Chain Size (A)</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>WLL (kgs)*</TableCell>
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Inside Length (B)</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Outside Width (C)</TableCell>            
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Weight (lbs/ft)</TableCell>            
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Max Single Length Shippable by UPS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
              <TableCell>30160005</TableCell>
              <TableCell>6mm</TableCell>
              <TableCell className={classes.tcell}>560</TableCell>
              <TableCell className={classes.tcell}>18.50</TableCell>
              <TableCell className={classes.tcell}>20.40</TableCell>
              <TableCell className={classes.tcell}>0.74</TableCell>
              <TableCell className={classes.tcell}>162 ft</TableCell>
        </TableRow>    
        <TableRow>
              <TableCell component="th" scope="row">30160010</TableCell>
              <TableCell component="th" scope="row"> 8mm </TableCell>
              <TableCell className={classes.tcell}>1000</TableCell>
              <TableCell className={classes.tcell}>24.00</TableCell>
              <TableCell className={classes.tcell}>	27.20</TableCell>
              <TableCell className={classes.tcell}>1.18</TableCell>
              <TableCell className={classes.tcell}>	101 ft</TableCell>
        </TableRow>   
        <TableRow>
              <TableCell component="th" scope="row">30160015</TableCell>
              <TableCell component="th" scope="row">10mm</TableCell>
              <TableCell className={classes.tcell}>1600</TableCell>
              <TableCell className={classes.tcell}>	28.00</TableCell>
              <TableCell className={classes.tcell}>34.00</TableCell>
              <TableCell className={classes.tcell}>1.60</TableCell>
              <TableCell className={classes.tcell}>	75 ft</TableCell>
        </TableRow>      
        </TableBody>
      </Table>
    </TableContainer>
 
    <Typography className={classes.pagesubtitle}>
        *Breaking Strength is 4 times the     Working Load Limit.
    </Typography>
    
      
    </Container>
   
    </div>
    )
}
