import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  pagetitle:{
   color: '#231f20',
   fontFamily:'Lato',
   paddingTop:'30px',
   fontSize: '50px',
   [theme.breakpoints.down("sm")]: {
      fontSize: '28px',
    },
 },
 pagesubtitle:{
  paddingBottom: '40px', 
  fontFamily: 'Lato',
  fontSize:'18px',
 },
 img:{
  width: '50%',
  height:'auto',
  margin:'5px' 
 },
[theme.breakpoints.down("sm")]: {
  width: '80%',
},
contact:{
  fontFamily:'Poppins',
  textDecoration: 'none',
  color: 'black',
  fontSize:'20px',
  margin:'10px'
},
contactIcons: {
  verticalAlign: 'text-bottom'
},
aboutimg: {
  height: "250px",
  display: "block",
  [theme.breakpoints.down("md")]: {
    height: "180px",
    display: "block",
    margin: "auto",
  },
},
}));