import React from "react";
import invlogo from "../assets/invlogo.png";
import { Link } from "react-router-dom";

class Scroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const img = window.scrollY < 125 ? false : true;
      this.setState({ display: img });
    });
  }
  render() {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        {this.state.display ? <Smallimg /> : null}
      </div>
    );
  }
}
export default Scroll;

function Smallimg() {
  return (
    
      <Link to="/">
        <img
          src={invlogo}
          alt=""
          style={{
            height: "26px",
            paddingLeft: "20px",
            display: "block",
            transform: "scale(1.1)",
            transition: "transform .5s ease",
            transitionDelay: "2s",
          }}
        />
      </Link>

  );
}
