import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pagetitle: {
    color: "#231f20",
    fontFamily: "Lato",
    paddingTop: "30px",
    fontSize: "50px",
  },
}));
