import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import {  Container, Typography, Grid } from '@material-ui/core';
import styles from './styles/ContactformStyles'
import './../App.css';
import { Helmet } from "react-helmet";

function Contactform() {
    const classes = styles();

    return (
        <div>
        
          <Helmet>
          <title> Contact Form</title>
          </Helmet>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center"className={classes.pagetitle} >
          CONTACT FORM
        </Typography>       
      </Container>
         <Grid container>
             <JotformEmbed src="https://form.jotform.com/200905310202940" />
          </Grid>            
      <div style={{marginBottom: '50px'}}></div>   
        </div>
    )
}
export default Contactform