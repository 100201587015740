import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,     
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  headerlink:{
    textDecoration: 'none',
    color: '#e4e4e4',
    fontFamily: 'Lato',
    fontSize: '25px',
},
  link:
  {
    textDecoration: 'none',
    color: '#e4e4e4',
    fontSize: '16px',
    fontFamily: 'Lato',
    lineHeight:'1.5',
   
  },
  textfield:{
    margin: '5px',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '20px',
    paddingLeft: '8px'
},
  signup:{
    color: 'white', 
    border: '2px solid white', 
    padding: '4px',
    margin: '3px',
  }, 
  form: {
    backgroundColor: "#FFF",
    fontSize: "200px;"
  }
}));


