import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  list: {
    width: 230,
  },
  fullList: {
    width: 'auto',
  },
  color: {
    backgroundColor: '#272e74',
},
reslogo: {
    height: '30px',
    paddingTop: '3px'   ,
    paddingRight: '10px'
},
reslink: {
    textDecoration: 'none',
    color: '#272e74',
    fontWeight: 'bold',
    fontSize: '17px',
    fontFamily: 'Lato',
},
reslink2: {
    textDecoration: 'none',
    color: '#272e74',
    fontSize: '16px',
    fontFamily: 'Lato',
},

});
