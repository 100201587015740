import React from 'react';
import clsx from 'clsx';
import styles from './styles/DrawerStyles'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Link} from 'react-router-dom'
import { AppBar, Toolbar, Grid, Box,  } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/invlogo.png'


export default function TemporaryDrawer() {
  const classes = styles();
  const [state, setState] = React.useState({    
    left: false,    
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <div className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>                           
              <ListItem key = {1} button divider>
             <Link to="/galvanized" className={classes.reslink}>ISO G4 Galvanized</Link></ListItem>
               <ListItem key = {2} button divider>
               <Link to="/bbb-galvanized" className={classes.reslink}>BBB Galvanized </Link></ListItem>
               <ListItem key = {3} button divider>
               <Link to="/din-galvanized" className={classes.reslink}>DIN 766 Galvanized</Link></ListItem>    
               <ListItem key = {4} button divider>
             <Link to="/stainless" className={classes.reslink}>G4 Stainless</Link></ListItem>            
               <ListItem key = {5} button divider>
               <Link to="/mooring" className={classes.reslink}>G4 Mooring Chain
              </Link></ListItem>            
               <ListItem key = {5} button divider>
               <Link to="/anchor-rodes" className={classes.reslink}>Anchor Rodes</Link></ListItem>  
            
               <ListItem key = {6} button divider> 
               <Link to="/" className={classes.reslink2}>Home</Link> </ListItem>               
               <ListItem key = {7} button divider>
               <a href="/#where-to-buy" className={classes.reslink2}>Where to Buy</a> </ListItem>
               <ListItem key = {8} button divider>
               <a href="/#contact" className={classes.reslink2}>Contact</a> </ListItem>
               <ListItem key = {9} button divider>
               <a target="_blank" rel="noopener noreferrer"href="https://e-rigging.com/collections/shop?q=trident%20marine%20chains" className={classes.reslink2}>Products</a></ListItem>               
      </List>
    </div>
  );

  return (
    <div>             
        <Box	display={{ xs: 'block', md: 'none', sm: 'block', lg: 'none' }} >
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
            <AppBar  className={classes.color} >        
          <Toolbar >
            <Grid container direction = "row" justify = "space-between"  >            
              <MenuIcon style={{fontSize:'30px', paddingTop:'6px'}} onClick={toggleDrawer(anchor, true)}>{anchor}/></MenuIcon>          
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
          <Link to="/"> 
          <img src={logo} className={classes.reslogo} alt=""/></Link>
          </Grid>
          </Toolbar>
        </AppBar>
        </React.Fragment>
      ))}
      </Box>
    </div>
  );
}