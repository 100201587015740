import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  pagetitle:{
      color: '#231f20',
      fontFamily:'Lato',
      paddingTop:'30px',
      fontSize: '42px',
      [theme.breakpoints.down("sm")]: {
        fontSize: '30px',
     }
    },
    pagesubtitle:{
     fontFamily: 'Lato',
     margin: '15px 10px',
     fontSize: '22px',
     [theme.breakpoints.down("sm")]: {
      fontSize: '14px',
   }
 },
 pagecontent: {
     fontFamily: 'Lato',
     margin: '10px',
     fontSize: '18px'
 },
 img: {
     height: '250px', 
     padding:'10px',
     marginBottom:'20px', 
 },
 link: {   
     fontFamily: 'Lato',
     margin: '25px',
     fontSize: '32px',
     float: 'right',
     '&:hover': {
         transform:'scale(1.1)',
         transition: 'transform .3s ease',
        }
 },
 card: {
     padding: '5px',
 },
 theader: {
     fontWeight: 'bold',
     fontFamily: 'Lato',
     fontSize: '18px',
     color: 'white'
    },
    tcell:{
      fontFamily: 'Lato' 
    },
  descriptionDiv: {
    backgroundColor: "#272E74",
    paddingLeft: "20px",
    marginBottom: 0,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px"
  }
  }));