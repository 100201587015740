import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  pagetitle:{
      color: '#231f20',
      fontFamily:'Lato',
      paddingTop:'30px',
      fontSize: '50px'
    },
    pagesubtitle:{
     fontFamily: 'Lato',
     margin: '15px',
     fontSize: '22px'
 },
 pagecontent: {
     fontFamily: 'Lato',
     margin: '10px',
     fontSize: '18px'
 },
    card: {
     border: '2px solid #272e74',
     padding: '5px',
     height: '250px'
 },
    theader: {
     fontWeight: 'bold',
     fontFamily: 'Lato',
     fontSize: '13px',
     color: 'white',
    },
    tcell:{
      fontFamily: 'Lato',
      borderRight: '.01px solid lightgrey' 
    },
    tcellEnd: {
      fontFamily: "Lato"
    }
}));

export const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
    marginTop: 0,
    display: "inline",
    padding: '3px'
  }),
};