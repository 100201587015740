import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Privacy from "./components/Privacy";
import Termsofuse from "./components/Termsofuse";
import Contactform from "./components/Contactform";
import G4 from "./components/G4";
import Bbb from "./components/Bbb";
import Din from "./components/Din";
import G4stainless from "./components/G4stainless";
import G4mooring from "./components/G4mooring";
import Anchor from "./components/Anchor";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Drawer from "./components/Drawer";
import Footer from "./components/Footer";
import Navbar from "./components/Appbar";
import FitmentChart from './components/FitmentChart/FitmentChart'
import useGaTracker from './useGaTracker'
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  useGaTracker();

  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <Box display={{ md: "block", lg: "none" }}>
        <Drawer />
      </Box>
      <Box display={{ xs: "none", sm: "block", md: "none" }}>
        <br />
        <br />
      </Box>
      <Switch>
        <Route path="/contact-form" component={Contactform}></Route>
        <Route path="/privacy-policy" component={Privacy}></Route>
        <Route path="/terms-of-use" component={Termsofuse}></Route>
        <Route exact path="/" component={Home}></Route>

        <Route path="/galvanized" component={G4}></Route>
        <Route path="/bbb-galvanized" component={Bbb}></Route>
        <Route path="/din-galvanized" component={Din}></Route>
        <Route path="/stainless" component={G4stainless}></Route>
        <Route path="/mooring" component={G4mooring}></Route>
        <Route path="/anchor-rodes" component={Anchor}></Route>
        <Route path="/fitment-chart" component={FitmentChart}></Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
