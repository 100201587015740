import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core';
import ModalImage from 'react-modal-image'
import styles from './styles/ProductStyles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DescriptionComponent from './DescriptionComponent';
import { Helmet } from "react-helmet";

 
export default function Anchor() {
    const classes = styles();
    return (
        <div>
              
            <Helmet>
                  <title> Anchor Rodes</title>
            </Helmet>
            <Container maxWidth="md" style={{minHeight:'50vh'}}>
             <Typography  align="center" className={classes.pagetitle} >
             Anchor Rodes
            </Typography>    
                

                <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={5} sm={5}>
            <div title="Anchor Rode and G4 Chain">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands.jpg?v=1646856734"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands.jpg?v=1646856734" hideDownload={true} hideZoom={true}
                      className={classes.card} alt="Anchor Rode and G4 Chain"
                     />   
            </div>  
              </Grid>  
              <Grid item  xs={10} md={5} sm={5}>
            <div title="Anchor Rode and G4 Chain Closeup">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/five-eigths-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands-close.jpg?v=1659477660"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/five-eigths-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands-close.jpg?v=1659477660" hideDownload={true} hideZoom={true}
                      className={classes.card}
                      alt="Anchor Rode and G4 Chain Closeup"
                    />   
            </div> 
              </Grid> 
        </Grid>   
        <DescriptionComponent 
            product="Anchor Rodes"
            subtitle="Trident G4 Galvanized chain sections are spliced into lengths of Nylon 8-Plait rope manufactured by United Strands of America under the Yachtkeeper brand."
            p1="Each Trident Marine Anchor Rode includes pre-installed Trident Marine Chains ISO Grade 40 Anchor Windlass Chain with short-pitch links engineered for maximum flexibility around the windlass and minimum wear. The Yachtkeeper rope is manufactured in the United States with high-quality 100% nylon for reliable performance."
            p2="Sizes include 1/2” rope with 1/4” windlass chain and 5/8” rope with 5/16” windlass chain, and lengths from 150 feet to 300 feet. Trident Marine Anchor Rodes are available in white nylon with red/blue tracers."
        />


        <Typography  align="center" className={classes.pagesubtitle} gutterBottom>
        1/2 inch - 5/8 inch Diameter
     </Typography> 

                 <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#272e74'}}>
          <TableRow>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Part Number</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Rope Type</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Rope Diameter</TableCell>
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Rope Length</TableCell>
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Chain Type</TableCell>            
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Chain Size</TableCell>            
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Chain Length</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
              <TableCell className={classes.tcell} >91406704</TableCell>
              <TableCell className={classes.tcell} >Yachtkeeper Nylon 8-Plait</TableCell>
              <TableCell className={classes.tcell}>1/2"</TableCell>
              <TableCell className={classes.tcell}>150'</TableCell>
              <TableCell className={classes.tcell}>Trident G4 Galvanized</TableCell>
              <TableCell className={classes.tcell}>1/4"</TableCell>
              <TableCell className={classes.tcell}>10'</TableCell>
        </TableRow>    
        <TableRow>
              <TableCell  className={classes.tcell}>91406718</TableCell>
              <TableCell  className={classes.tcell}> Yachtkeeper Nylon 8-Plait </TableCell>
              <TableCell className={classes.tcell}>1/2"</TableCell>
              <TableCell className={classes.tcell}>200'</TableCell>
              <TableCell className={classes.tcell}>	Trident G4 Galvanized</TableCell>
              <TableCell className={classes.tcell}>1/4"</TableCell>
              <TableCell className={classes.tcell}>	15'</TableCell>
        </TableRow>   
        <TableRow>
              <TableCell  className={classes.tcell}>91406744</TableCell>
              <TableCell  className={classes.tcell}>Yachtkeeper Nylon 8-Plait</TableCell>
              <TableCell className={classes.tcell}>1/2"</TableCell>
              <TableCell className={classes.tcell}>	300'</TableCell>
              <TableCell className={classes.tcell}>Trident G4 Galvanized</TableCell>
              <TableCell className={classes.tcell}>1/4"</TableCell>
              <TableCell className={classes.tcell}>	20'</TableCell>
        </TableRow> 
        <TableRow>
              <TableCell  className={classes.tcell}>91406752</TableCell>
              <TableCell  className={classes.tcell}>Yachtkeeper Nylon 8-Plait</TableCell>
              <TableCell className={classes.tcell}>5/8"</TableCell>
              <TableCell className={classes.tcell}>	150'</TableCell>
              <TableCell className={classes.tcell}>Trident G4 Galvanized</TableCell>
              <TableCell className={classes.tcell}>5/16"</TableCell>
              <TableCell className={classes.tcell}>10'</TableCell>
        </TableRow> 
        <TableRow>
              <TableCell  className={classes.tcell}>91406766</TableCell>
              <TableCell  className={classes.tcell}>Yachtkeeper Nylon 8-Plait</TableCell>
              <TableCell className={classes.tcell}>5/8"</TableCell>
              <TableCell className={classes.tcell}>	200'</TableCell>
              <TableCell className={classes.tcell}>Trident G4 Galvanized</TableCell>
              <TableCell className={classes.tcell}>5/16"</TableCell>
              <TableCell className={classes.tcell}>	15'</TableCell>
        </TableRow> 
        <TableRow>
              <TableCell  className={classes.tcell}>91406792</TableCell>
              <TableCell  className={classes.tcell}>Yachtkeeper Nylon 8-Plait</TableCell>
              <TableCell className={classes.tcell}>5/8"</TableCell>
              <TableCell className={classes.tcell}>	300'</TableCell>
              <TableCell className={classes.tcell}>Trident G4 Galvanized</TableCell>
              <TableCell className={classes.tcell}>5/16"</TableCell>
              <TableCell className={classes.tcell}>20'</TableCell>
        </TableRow>      
        </TableBody>
      </Table>
    </TableContainer>    
             </Container>
        </div>
    )
}
