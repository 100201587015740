import React from 'react'
import {  Container, Typography, Grid } from '@material-ui/core';
import styles from './styles/WheretobuyStyles'
import Erigging from './../assets/erigging.png'
import sld from './../assets/sld-logo.svg'



export default function Wheretobuy () {
    const classes = styles();
    return (
        <div id="where-to-buy" style={{paddingBottom: '150px', paddingTop: '100px'}}>    
            <Container maxWidth="md" component="main" >
            <Typography  align="center" className={classes.pagetitle} gutterBottom>
                Where to Buy
                </Typography>   
       

             <Grid container spacing={3} justify="center" alignItems="center" style={{textAlign:'center'}}>
          <Grid item xs={12} md={12} lg={12}>
            <a
              href="https://e-rigging.com/collections/shop?q=trident%20marine%20chains"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Erigging} className={classes.img} alt="" />
            </a>
          </Grid>
          {/* <Grid item  xs={12} lg={4}>
            <a
              href="https://www.amazon.com/s?k=Thor-Tex"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={wtb1} className={classes.img} alt="" />
            </a>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={6}>
            <a
              href="https://www.sldrigging.com/contactform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sld} className={classes.img} alt="" />
              
            </a>
          
          </Grid> */}
        </Grid>
        </Container>
        </div>
    )
}



