import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core';
import styles from './styles/ProductStyles'
import ModalImage from "react-modal-image";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FitmentChart from './FitmentChart/FitmentChart';
import DescriptionComponent from './DescriptionComponent';
import { Helmet } from "react-helmet";

export default function G4stainless() {
    const classes = styles();
    return (
        <div>
              <Helmet>
<title> G4 Stainless</title>
</Helmet>
     <Container maxWidth="md" component="main" style={{minHeight:'50vh'}}>
        <Typography  align="center" className={classes.pagetitle} >
             G4 Stainless
        </Typography>  

          
         <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={5} sm={5}>
            <div  title="Trident Marine Stainless Steel Chain Links">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/ss-chain.jpg?v=1678783687"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/ss-chain.jpg?v=1678783687" hideDownload={true} hideZoom={true}
                      className={classes.card} 
                      alt="Trident Marine Stainless Steel Chain Links"
                     />     
                     </div>
              </Grid>  
              <Grid item  xs={10} md={5} sm={5}>
                  <div title="Trident Marine Chain Link Diagram">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585" hideDownload={true} hideZoom={true}
                      className={classes.card}
                      alt="Trident Marine Chain Link Diagram"
                    />   
                    </div>
              </Grid> 
        </Grid>
        <DescriptionComponent 
            product="G4 Stainless"
            subtitle="The G4 refers to Grade 40 which denotes greater strength than Grade 30 proof coil chain and is stronger than BBB chain and DIN766 chains. "
            p1="ecause the inside length and width of each chain link must consistently and properly fit the chain gypsies on anchor windlasses, each chain link is calibrated during the manufacturing process and conforms with ISO chain standards. Every 3 feet, a link of chain is embossed with “TI6” which indicates that it is a Trident Marine Chain ISO Grade 40 stainless steel chain product. This chain is made from Type 316 Marine Grade Stainless Steel."
            p2="Made from type 316 stainless steel, Trident Marine Chains Type 316 stainless steel ISO Grade 40 anchor windlass chain is a short pitch type chain that makes it more flexible and suitable for working with anchor windlass gypsies. Each link of chain is proof tested and calibrated to assure strength and fit with the windlass gypsy."
            p3="Marine grade type 316 stainless steel will provide maximum protection from corrosive saltwater environment. Trident Marine Chains type 316 stainless steel Grade 40 windlass anchor chain is embossed every 1 meter with the “TI6” marking that indicates it to be a Trident type 316 stainless steel ISO Grade 40 chain."
        />
       
    <Typography  align="center" className={classes.pagesubtitle} gutterBottom>
        1/4 inch - 1/2 inch Diameter
     </Typography> 

    <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#272e74'}}>
          <TableRow>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Part Number</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Nominal Chain Size (A)</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>WLL (lbs)*</TableCell>
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Inside Length (B)</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Inside Width (C)</TableCell>            
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Weight (lbs/ft)</TableCell>            
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Max Single Length Shippable by UPS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
              <TableCell >31561031</TableCell>
              <TableCell >1/4"</TableCell>
              <TableCell className={classes.tcell}>2000</TableCell>
              <TableCell className={classes.tcell}>0.84</TableCell>
              <TableCell className={classes.tcell}>0.4</TableCell>
              <TableCell className={classes.tcell}>0.77</TableCell>
              <TableCell className={classes.tcell}>155 ft</TableCell>
        </TableRow>    
        <TableRow>
              <TableCell component="th" scope="row">31561051</TableCell>
              <TableCell component="th" scope="row"> 5/16" </TableCell>
              <TableCell className={classes.tcell}>2400</TableCell>
              <TableCell className={classes.tcell}>1.02</TableCell>
              <TableCell className={classes.tcell}>0.48</TableCell>
              <TableCell className={classes.tcell}>1.12</TableCell>
              <TableCell className={classes.tcell}>107 ft</TableCell>
        </TableRow>   
        <TableRow>
              <TableCell component="th" scope="row">31561061</TableCell>
              <TableCell component="th" scope="row">3/8"</TableCell>
              <TableCell className={classes.tcell}>3750</TableCell>
              <TableCell className={classes.tcell}>	1.22</TableCell>
              <TableCell className={classes.tcell}>0.58</TableCell>
              <TableCell className={classes.tcell}>1.57</TableCell>
              <TableCell className={classes.tcell}>	76 ft</TableCell>
        </TableRow>  
        <TableRow>
              <TableCell component="th" scope="row">31561071</TableCell>
              <TableCell component="th" scope="row">1/2" </TableCell>
              <TableCell className={classes.tcell}>6500</TableCell>
              <TableCell className={classes.tcell}>1.6</TableCell>
              <TableCell className={classes.tcell}>0.76</TableCell>
              <TableCell className={classes.tcell}>2.5</TableCell>
              <TableCell className={classes.tcell}>48 ft</TableCell>
        </TableRow>      
        </TableBody>
      </Table>
    </TableContainer>

    <Typography className={classes.pagesubtitle}>
        *Breaking Strength is 4 times the     Working Load Limit.
    </Typography>
   
      
    </Container>
    
     </div>
    )
}
