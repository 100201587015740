import React, { useState } from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import styles, { selectStyles } from "../styles/FitmentChartStyles";
import FitmentItem from "./FitmentItem";
import chains from "./windlass-chains";
import options from "./FitmentOptions";

const FitmentChart = () => {
  const classes = styles();

  const [manufacturerState, setManu] = useState("");
  const [modelState, setModl] = useState("");

  const handleModel = (e) => {
    setModl(e.target.value);
  };

  const handleSelect = (selectedOption) => {
    if (selectedOption !== null) {
      setManu(selectedOption.value);
    }
    if (selectedOption == null) {
      setManu("");
    }
  };

  return (
    <Container
      maxWidth="md"
      component="main"
      style={{
        minHeight: "50vh",
        paddingTop: "1.5rem",
        marginBottom: "1.5rem",
      }}
    >
      <Typography
        variant="h4"
        style={{ textAlign: "center", fontFamily: "Lato" }}
      >
        FITMENT CHART
      </Typography>
      <div>
        <Typography
          variant="button"
          style={{
            marginRight: "10px",
            fontFamily: "Lato",
            fontWeight: "bold",
          }}
        >
          Filters
        </Typography>
      </div>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div style={{ display: "inline-block", width: "50%" }}>
          <Select
            placeholder="Filter by Manufacturer"
            styles={selectStyles}
            options={options}
            isClearable
            onChange={handleSelect}
          ></Select>
        </div>

        <OutlinedInput
          margin="dense"
          type="text"
          placeholder="Filter by Model"
          onChange={handleModel}
          value={modelState}
          inputProps={{
            style: {
              padding: 10
            }
          }}
          style={{
            marginTop: 0,
            marginBottom: '5px',
            fontFamily: "Lato",
            display: "inline-block",
            backgroundColor: "white",
            color: "black",
            padding: '0'
          }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label="Fitment Chart"
          style={{ padding: "5px" }}
        >
          <TableHead style={{ backgroundColor: "#272e74" }}>
            <TableCell className={classes.theader}>Manufacturer </TableCell>
            <TableCell className={classes.theader}>Model </TableCell>
            <TableCell className={classes.theader}>
              Gypsy or Chain Wheel No. or I.D. Reference
            </TableCell>
            <TableCell className={classes.theader}>Chain Size</TableCell>
            <TableCell className={classes.theader}>
              Trident Marine High Test ISO Grade 4
            </TableCell>
            <TableCell className={classes.theader}>
              Trident Marine BBB
            </TableCell>
          </TableHead>
          <TableBody>
            {/* Loop thru filtered list and render appropriate chart */}
            {chains
              .filter((item) => {
                if (
                  item.Manufacturer.toLowerCase().includes(
                    manufacturerState.toLowerCase()
                  ) &&
                  item.Model.toLowerCase().includes(modelState.toLowerCase())
                ) {
                  return true;
                }
              })
              .map((itm) => (
                <FitmentItem
                  manufacturer={itm.Manufacturer}
                  model={itm.Model}
                  chain={itm.GypsyOrChain}
                  chainSize={itm.ChainSize}
                  iso={itm.ISOGrade}
                  BBB={itm.BBB}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default FitmentChart;
