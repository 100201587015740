import React from "react";
import { TableCell, TableRow } from '@material-ui/core'
import styles from '../styles/FitmentChartStyles'

const FitmentItem = ({manufacturer, model, chain, chainSize, iso, BBB}) => {
  const classes = styles()
  return (
    <TableRow>
      <TableCell className={classes.tcell}>{manufacturer}</TableCell>
      <TableCell className={classes.tcell}>{model}</TableCell>
      <TableCell className={classes.tcell}>{chain}</TableCell>
      <TableCell className={classes.tcell}>{chainSize}</TableCell>
      <TableCell className={classes.tcell}>{iso}</TableCell>
      <TableCell className={classes.tcellEnd}>{BBB}</TableCell>
  </TableRow>
  )
}

export default FitmentItem
