import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styles from './styles/AppBarStyles'
import Topnav from '../components/Topnav'
import Scroll from './Scroll'
import {Link} from 'react-router-dom';




function ElevateAppBar() {
  const classes = styles();
  return (    
    <>
      <Topnav/> 
      <AppBar className={classes.root} position="sticky" 
      style={{ backgroundColor: '#272e74'}}>        
        <Toolbar >
        <Typography  align="center" style={{padding: '3px'}}> 
        <Scroll/>       
          </Typography>     

          <Typography variant="subheading"color="inherit" style={{flexGrow:1}}> 
            <Link to="/galvanized" className={classes.link}>ISO G4 GALVANIZED</Link>
          </Typography>
          <Typography variant="subheading" color="inherit" style={{flexGrow:1}}> 
            <Link to="/bbb-galvanized" className={classes.link}>BBB GALVANIZED</Link>
          </Typography>
          <Typography variant="subheading" color="inherit" style={{flexGrow:1}}> 
            <Link to="/din-galvanized" className={classes.link}>DIN 766 GALVANIZED</Link>
            </Typography>  
          <Typography variant="subheading"color="inherit" style={{flexGrow:1}}> 
            <Link to="/stainless" className={classes.link}>G4 STAINLESS</Link>
          </Typography> 
          <Typography variant="subheading"color="inherit" style={{flexGrow:1}}> 
            <Link to="/mooring" className={classes.link}>  G4 MOORING CHAIN</Link>
          </Typography>
          <Typography variant="subheading"color="inherit" style={{flexGrow:1}}> 
            <Link to="/anchor-rodes" className={classes.link}> ANCHOR RODES</Link>
          </Typography> 

      </Toolbar>
      </AppBar>       
    </>
  );
};


export default ElevateAppBar;