import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/AboutStyles";
import AboutImg from './../assets/AboutImg.jpg'

export default function About() {
  const classes = styles();
  return (
    <div id="about" style={{ backgroundColor: "#f5f5f5", display:'flex', padding:'4% 0' }}>
      <Container
        maxWidth="lg"
        style={{
          flexGrow:'1'
        }}
      >
        <div style={{ flexBasis: "none" }}>
          <Grid container >
            <Grid item lg={5} xs={12} md={4} style={{ display:'flex', alignItems:'center', }}>
               <img src={AboutImg} className={classes.aboutimg} alt="aboutimg" /> 
            </Grid>

            <Grid item lg={7} xs={12} md={8} style={{padding:'0%'}}>
              <Typography align="center" className={classes.pagetitle}>
                About Us
              </Typography>
              <Typography className={classes.pagesubtitle} >
              The trident is the traditional symbol of Poseidon, the Greek god of the sea and of earthquakes. Trident Marine Chains products are products of <a href="https://sldrigging.com/">Sea‐Land Distributors, LLC.</a> Our marine-grade Trident steel chains can also move mountains. Our years of experience with rigging and chains means we design our products with the strength and reliability our customers need. Available in stainless and galvanized steel and with a variety of pitch lengths, our Trident chains are ready for any job you throw at them. We sell Trident Chains by the foot so you get the most out of your purchase with no waste. Rule the waves today with Trident Chains. 
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
