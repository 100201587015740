import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  pagetitle:{
   color: '#231f20',
   fontFamily:'Lato',
   padding:'30px',
   fontSize: '50px',
   [theme.breakpoints.down("sm")]: {
      fontSize: '28px',
    },
 },

 img:{
  height: "57%",
  width: "57%",
  '&:hover': {
   transform:'scale(1.1)',
   transition: 'transform .3s ease',
  },
  [theme.breakpoints.down("md")]: {
    height: '57%',
    width: '57%'
  },
}
}));