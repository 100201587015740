import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  pagetitle:{
    color: '#231f20',
    fontFamily:'Lato',
    marginTop:'60px',
    fontSize: '42px'
  },
  pagesubtitle:{
   fontFamily: 'Lato',
   fontSize: '18px',
   fontWeight: 'bold',
   marginBottom:'2px'
  },
  pagecontent: {
    fontFamily: 'Lato',
    fontSize: '16px',
    marginBottom:'10px'
  }
}));