import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FitmentChart from "./FitmentChart/FitmentChart";
import DescriptionComponent from "./DescriptionComponent";
import { Helmet } from "react-helmet";

export default function Bbb() {
  const classes = styles();
  return (
    <div>
      <Helmet>
        <title> BBB Galvanized</title>
      </Helmet>
      <Container maxWidth="md" component="main" style={{ minHeight: "50vh" }}>
        <Typography align="center" className={classes.pagetitle}>
          BBB Galvanized
        </Typography>

        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={5} sm={5}>
            <div title="Trident Marine BBB Chain Links">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Trident-Marine-BBB-Chain.jpg?v=1678783426"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Trident-Marine-BBB-Chain.jpg?v=1678783426"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Trident Marine BBB Chain Links"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={5} sm={5}>
            <div  title="Trident Marine Chain Diagram">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Trident Marine Chain Diagram"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent
          product="BBB Galvanized"
          subtitle=" BBB chain has shorter links that allow the chain to articulate very well and smoothly operate in and out of chain lockers and across the chain gypsies."
          p1="Because the inside length and width of each chain link must consistently and properly fit the chain gypsies on anchor windlasses, each chain link is calibrated during the manufacturing process.  
          "
          p2="Every 3 feet, a link of chain is embossed with “TDB” which indicates that it is a Trident Marine Chain BBB chain product.  
          To ensure long service life, a thick layer of zinc is applied through the hot dip galvanizing process."
          p3=" Made from low carbon steel, Trident Marine Chains ISO BBB 30 anchor windlass chain is a particularly short pitch type chain that makes it even more flexible and suitable for working with anchor windlass gypsies. Each link of chain is proof tested and calibrated to assure strength and fit with the windlass gypsy."
          p4="To provide long service life and guard against corrosion, Trident BBB Windlass Anchor chains are hot dip galvanized. Trident Marine Chains BBB Grade 30 windlass anchor chain is embossed every 1 meter with the “TDB” marking that indicates it to be a Trident BBB Grade 30 chain."
        />
        <Typography className={classes.pagesubtitle}></Typography>
        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          1/4 inch - 3/8 inch Diameter
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#272e74" }}>
              <TableRow>
                <TableCell
                  style={{ color: "white", fontWeight: "bold" }}
                  className={classes.theader}
                >
                  Part Number
                </TableCell>
                <TableCell
                  style={{ color: "white", fontWeight: "bold" }}
                  className={classes.theader}
                >
                  Nominal Chain Size (A)
                </TableCell>
                <TableCell
                  style={{ color: "white", fontWeight: "bold" }}
                  className={classes.theader}
                >
                  WLL (lbs)*
                </TableCell>
                <TableCell
                  style={{ color: "white", fontWeight: "bold" }}
                  className={classes.theader}
                >
                  Inside Length (B)
                </TableCell>
                <TableCell
                  style={{ color: "white", fontWeight: "bold" }}
                  className={classes.theader}
                >
                  Inside Width (C)
                </TableCell>
                <TableCell
                  style={{ color: "white", fontWeight: "bold" }}
                  className={classes.theader}
                >
                  Weight (lbs/ft)
                </TableCell>
                <TableCell
                  style={{ color: "white", fontWeight: "bold" }}
                  className={classes.theader}
                >
                  Max Single Length Shippable by UPS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>30160025</TableCell>
                <TableCell>1/4"</TableCell>
                <TableCell className={classes.tcell}>1300</TableCell>
                <TableCell className={classes.tcell}>0.87</TableCell>
                <TableCell className={classes.tcell}>0.43</TableCell>
                <TableCell className={classes.tcell}>0.74</TableCell>
                <TableCell className={classes.tcell}>162 ft</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  30160030
                </TableCell>
                <TableCell component="th" scope="row">
                  {" "}
                  5/16"{" "}
                </TableCell>
                <TableCell className={classes.tcell}>1900</TableCell>
                <TableCell className={classes.tcell}>1.00</TableCell>
                <TableCell className={classes.tcell}> 0.50</TableCell>
                <TableCell className={classes.tcell}>1.18</TableCell>
                <TableCell className={classes.tcell}> 127 ft</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  30160035
                </TableCell>
                <TableCell component="th" scope="row">
                  3/8"
                </TableCell>
                <TableCell className={classes.tcell}>2650</TableCell>
                <TableCell className={classes.tcell}> 1.09</TableCell>
                <TableCell className={classes.tcell}>0.62</TableCell>
                <TableCell className={classes.tcell}>1.60</TableCell>
                <TableCell className={classes.tcell}> 76 ft</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography className={classes.pagesubtitle}>
          *Breaking Strength is 4 times the Working Load Limit.
        </Typography>

        <Typography className={classes.pagecontent}></Typography>
      </Container>
      <FitmentChart />
    </div>
  );
}
