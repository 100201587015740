import React from 'react';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/logo.svg'
import {Link} from 'react-router-dom';
import styles from './styles/TopnavStyles'
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';



function Topnav () { 
  const classes = styles();

  return (
    <>   
      <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>   
      <Grid  container  direction="row"  justify="space-around"  alignItems="center" style={{backgroundColor: '#e4e4e4'}}>
      <Grid container item lg={5} md={5}>
      <Link to="/">
             <img src={logo} className={classes.logo} alt=""/></Link>
      </Grid>
      <Grid container item lg={7} md={7} justify="flex-end">
      <Typography className = {classes.toplink} color="inherit" > 
          <a href="https://e-rigging.com/collections/shop?q=trident%20marine%20chains" className={classes.toplink} target="_blank" rel="noopener noreferrer">Products</a></Typography> 

          <Typography variant = "subheading" className = {classes.toplink} color="inherit" > 
          <a href="/#about" className={classes.toplink}>About</a></Typography>
          <Typography variant = "subheading" className = {classes.toplink} color="inherit" > 
          <a href="/#contact" className={classes.toplink}>Contact</a></Typography>

          <Typography variant = "subheading" className = {classes.toplink} color="inherit" >
           <a href="/#where-to-buy" className={classes.toplink}>Where to Buy</a>
           </Typography>   

      </Grid>
      </Grid>         
            
      </Box>  
      {/* mobile  */}
      <Box	display={{ xs: 'block', sm: 'none' }} > 
      <div align="center" style={{marginTop: '55px'}}>               
      </div>        
      </Box>      
    </>
  );
};

export default Topnav;