import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core';
import styles from './styles/ProductStyles'
import ModalImage from "react-modal-image";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FitmentChart from './FitmentChart/FitmentChart';
import DescriptionComponent from './DescriptionComponent';
import { Helmet } from "react-helmet";

export default function About() {
    const classes = styles();
    return (
      <div>
        
<Helmet>
<title> G4 Mooring Chain</title>
</Helmet>
      <Container maxWidth="md" component="main" style={{minHeight:'50vh'}}>
      <Typography  align="center" className={classes.pagetitle} >
         G4 Mooring Chain
      </Typography> 
     

      <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={5} sm={5}>
            <div title="Trident Marine Mooring Chain Single Link">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/mooring-chain-single-link.jpg?v=1658181004"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/mooring-chain-single-link.jpg?v=1658181004" hideDownload={true} hideZoom={true}
                      className={classes.card} 
                      alt="Trident Marine Mooring Chain Single Link"
                     />     
                     </div>
              </Grid>  
              
              <Grid item  xs={10} md={5} sm={5}>
            <div  title="Trident Marine Chain Link Diagram">
                <ModalImage
                      small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585"
                      large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585" hideDownload={true} hideZoom={true}
                      className={classes.card}
                      alt="Trident Marine Chain Link Diagram"
                    />   
                    </div>
              </Grid> 
              
        </Grid>   
        <DescriptionComponent 
          product="G4 Mooring Chain"
          subtitle="The G4 refers to Grade 40 which denotes greater strength than Grade 30 proof coil chain."
          p1="Trident Mooring Chain has longer links to reduce the weight of the chain, but not reduce the strength. This makes it ideal for mooring applications where the chain is connected to a weight or anchor up to a buoy on the surface. The longer links also allow for connection to shackles or swivel fittings. Every 3 feet, a link of chain is embossed with “TM4” which indicates that it is a Trident Marine Chain Grade 40 mooring chain product. To ensure long service life, a thick layer of zinc is applied through the hot dip galvanizing process."
          p2="Made from high tensile strength carbon steel, Trident Marine Chains Grade 40 mooring chain is a long pitch type chain that makes it more suitable for use with shackles. Each link of chain is proof tested."
          p3="To provide long service life and guard against corrosion, Trident Grade 40 mooring chains are hot dip galvanized. Trident Marine Chains Grade 40 mooring chain is embossed every 1 meter with the “TM4” marking that indicates it to be a Trident Grade 40 mooring chain."
        />
       
      <Typography  align="center" className={classes.pagesubtitle} gutterBottom>
        3/8 inch - 3/4 inch Diameter
     </Typography> 
     
    <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#272e74'}}>
          <TableRow>
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Part Number</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Nominal Chain Size (A)</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>WLL (lbs)*</TableCell>
            <TableCell style={{color: "white", fontWeight: "bold"}}  className={classes.theader}>Inside Length (B)</TableCell>
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Inside Width (C)</TableCell>            
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Weight (lbs/ft)</TableCell>            
            <TableCell  style={{color: "white", fontWeight: "bold"}} className={classes.theader}>Max Single Length Shippable by UPS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
              <TableCell>30170005</TableCell>
              <TableCell>3/8"</TableCell>
              <TableCell className={classes.tcell}>4050</TableCell>
              <TableCell className={classes.tcell}>1.62</TableCell>
              <TableCell className={classes.tcell}>0.65</TableCell>
              <TableCell className={classes.tcell}>1.36</TableCell>
              <TableCell className={classes.tcell}>88 ft</TableCell>
        </TableRow>    
        <TableRow>
              <TableCell component="th" scope="row">30170010</TableCell>
              <TableCell component="th" scope="row">1/2"</TableCell>
              <TableCell className={classes.tcell}>6900</TableCell>
              <TableCell className={classes.tcell}>2.00</TableCell>
              <TableCell className={classes.tcell}>0.87</TableCell>
              <TableCell className={classes.tcell}>2.36</TableCell>
              <TableCell className={classes.tcell}>50 ft</TableCell>
        </TableRow> 
        <TableRow>
              <TableCell component="th" scope="row">30170015</TableCell>
              <TableCell component="th" scope="row">5/8" </TableCell>
              <TableCell className={classes.tcell}>9750</TableCell>
              <TableCell className={classes.tcell}>2.53</TableCell>
              <TableCell className={classes.tcell}>0.92</TableCell>
              <TableCell className={classes.tcell}>3.82</TableCell>
              <TableCell className={classes.tcell}>31 ft</TableCell>
        </TableRow>        
        <TableRow>
              <TableCell component="th" scope="row">30170020</TableCell>
              <TableCell component="th" scope="row">3/4"</TableCell>
              <TableCell className={classes.tcell}>15000</TableCell>
              <TableCell className={classes.tcell}>	2.99</TableCell>
              <TableCell className={classes.tcell}>1.04</TableCell>
              <TableCell className={classes.tcell}>5.27</TableCell>
              <TableCell className={classes.tcell}>22 ft</TableCell>
        </TableRow>  
        </TableBody>
      </Table>
    </TableContainer>

    <Typography className={classes.pagesubtitle}>
        *Breaking Strength is 4 times the Working Load Limit.
    </Typography>
    
        
        </Container>
                 
      
        </div>
    )
}
