import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FitmentChart from "./FitmentChart/FitmentChart";
import DescriptionComponent from "./DescriptionComponent";
import { Helmet } from "react-helmet";

export default function G4() {
  const classes = styles();
  return (
    <div>
      <Helmet>
        <title> ISO G4 Galvanized</title>
      </Helmet>
      <Container maxWidth="md" component="main" style={{ minHeight: "50vh" }}>
        <Typography align="center" className={classes.pagetitle}>
          ISO G4 Galvanized
        </Typography>

        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={5} sm={5}>
            <div  title="ISO G40 Single Link Chain">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/ISO-G40-Single-Link.jpg?v=1658180300"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/ISO-G40-Single-Link.jpg?v=1658180300"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="ISO G40 Single Link Chain"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={5} sm={5}>
            <div title="Chain diagram">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/trident-chain-drawing.png?v=1653595585"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Chain diagram"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent
          product="ISO G4 Galvanized"
          subtitle="The G4 refers to Grade 40 which denotes greater strength than Grade 30 proof coil chain and is stronger than BBB chain and DIN766 chains."
          p1=" Because the inside length and width of each chain link must consistently and properly fit the chain gypsies on anchor windlasses, each chain link is calibrated during the manufacturing process and conforms with ISO chain standards.  Every 3 feet, a link of chain is embossed with “TI4” which indicates that it is a Trident Marine Chain ISO Grade 40 chain product. 
                   To ensure long service life, a thick layer of zinc is applied through the hot dip galvanizing process. "
          p2="Made from high tensile strength carbon steel, Trident Marine Chains ISO Grade 40 anchor windlass chain is a short pitch type chain that makes it more flexible and suitable for working with anchor windlass gypsies. Each link of chain is proof tested and calibrated to assure strength and fit with the windlass gypsy."
          p3="To provide long service life and guard against corrosion, Trident ISO Windlass Anchor chains are hot dip galvanized. Trident Marine Chains ISO Grade 40 windlass anchor chain is embossed every 1 meter with the “TI4” marking that indicates it to be a Trident ISO Grade 40 chain."
        />

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          1/4 inch - 1/2 inch Diameter
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#272e74" }}>
              <TableRow>
                <TableCell
                  className={classes.theader}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  Part Number
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  Nominal Chain Size (A)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  WLL (lbs)*
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  Inside Length (B)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  Inside Width (C)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  Weight (lbs/ft)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  Max Single Length Shippable by UPS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>30150005</TableCell>
                <TableCell>1/4"</TableCell>
                <TableCell className={classes.tcell}>2600</TableCell>
                <TableCell className={classes.tcell}>0.85</TableCell>
                <TableCell className={classes.tcell}>0.41</TableCell>
                <TableCell className={classes.tcell}>0.73</TableCell>
                <TableCell className={classes.tcell}>193 ft</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  30150010
                </TableCell>
                <TableCell component="th" scope="row">
                  5/16"
                </TableCell>
                <TableCell className={classes.tcell}>3900</TableCell>
                <TableCell className={classes.tcell}>1.03</TableCell>
                <TableCell className={classes.tcell}>0.50</TableCell>
                <TableCell className={classes.tcell}>1.05</TableCell>
                <TableCell className={classes.tcell}>144 ft</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  30150015
                </TableCell>
                <TableCell component="th" scope="row">
                  3/8"
                </TableCell>
                <TableCell className={classes.tcell}>5400</TableCell>
                <TableCell className={classes.tcell}>1.22</TableCell>
                <TableCell className={classes.tcell}>0.60</TableCell>
                <TableCell className={classes.tcell}>1.57</TableCell>
                <TableCell className={classes.tcell}>173 ft</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  30150020
                </TableCell>
                <TableCell component="th" scope="row">
                  1/2"{" "}
                </TableCell>
                <TableCell className={classes.tcell}>9200</TableCell>
                <TableCell className={classes.tcell}>1.59</TableCell>
                <TableCell className={classes.tcell}>0.77</TableCell>
                <TableCell className={classes.tcell}>2.54</TableCell>
                <TableCell className={classes.tcell}>47 ft</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography className={classes.pagesubtitle}>
          *Breaking Strength is 4 times the Working Load Limit.
        </Typography>
      </Container>
      <FitmentChart />
    </div>
  );
}
