import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  
  },
  appBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: "center" 
  },
  tree:{
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  list : {
    width : 200,
    fontFamily: 'Poppins'
  },  
  sideBarIcon : {
    color : "white",
    cursor : "pointer",
  },
  link:
    { 
      fontFamily: 'Lato',
      paddingLeft: '2px',
      paddingRight: '2px',
      fontSize: '18px',      
      textDecoration: 'none',
      color: '#e4e4e4',
      display:'flex',
      justifyContent: 'space-evenly',
      '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
     },   
    },
    reslink:
    {
        textDecoration: 'none',
        color: '#213C54',
    },
     reslink2:
    {
        textDecoration: 'none',
        color: '#213C54',
       fontSize:'15px'
    },
}));