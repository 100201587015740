
import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './styles/FooterStyles'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {  Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import MChimp from './Mailchimp'
import { animateScroll as scroll } from 'react-scroll';
import JotformEmbed from 'react-jotform-embed';

function Copyright() {
  return (
    <Typography variant="body2" style={{color: '#e4e4e4', fontFamily: 'Lato'}}>      
       <Link to='/privacy-policy' style={{textDecoration: 'none', color: '#e4e4e4'}}>Privacy Policy |</Link>
       <Link to='/terms-of-use' style={{textDecoration: 'none', color: '#e4e4e4'}}> Terms of Use | </Link>
       <Box display={{ xs: 'block', sm: 'none' }}><br/></Box>
      {'Copyright ©     '}
      <Link to='/' color="inherit" 
      style={{textDecoration: 'none', color: '#e4e4e4'}}>
         Sea-Land Distributors, LLC. 
      </Link>
      {new Date().getFullYear()}
     
    </Typography>
  );
}


const handleScroll = (event) => {
  scroll.scrollToTop();
};

export default function Footer() {
  const classes = styles(); 
  return (
    <div style={{backgroundColor: '#272e74'}}>
    <Container maxWidth="lg" component="footer" className={classes.footer} >
        <Grid container spacing={2}>        
            <Grid item xs={6} sm={3} md={4} lg={4} >
              <Typography gutterBottom className={classes.headerlink}>
              Trident Marine Chains           
              </Typography>
              <ul>    
              <Link to='/' className={classes.link} onClick={handleScroll}><li>Home  </li></Link>                              
              <a href='/#where-to-buy' className={classes.link}><li>Where to Buy </li>  </a>  
              <a href='/#contact' className={classes.link}><li>Contact  </li> </a>  
              <a target="_blank" rel="noopener noreferrer" href='https://e-rigging.com/collections/shop?q=trident%20marine%20chains' className={classes.link}><li>Products  </li> </a>                         
              </ul>
            </Grid>  
            <Grid item xs={6} sm={3} md={4} lg={4}>
              <Typography gutterBottom className={classes.headerlink}>                
              Quick Links
              </Typography>                 
              <ul>
              <Link to='/galvanized' className={classes.link}><li>ISO G4 Galvanized</li></Link>  
              <Link to='/bbb-galvanized' className={classes.link}><li>BBB Galvanized  </li></Link>
              <Link to='/din-galvanized' className={classes.link}><li>DIN 766 Galvanized </li></Link>    
              <Link to='/stainless' className={classes.link}><li>G4 Stainless </li></Link>
              <Link to='/anchor-rodes' className={classes.link}><li>Anchor Rodes </li></Link>
              </ul>              
            </Grid>  
            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography className={classes.headerlink} style={{paddingLeft: '20px'}}> N E W S L E T T E R</Typography>             
            <Typography className={classes.link} style={{paddingLeft:'20px'}}>Sign up for new Trident Marine Chains releases</Typography>                       
            <JotformEmbed src="https://form.jotform.com/222425926825157" />
        </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
        <div style={{paddingBottom: '30px'}}></div>
      </Container>
      </div>
  );
}